.app {
  margin: 0px;
  height: 100%;
  /*display: flex;
  flex-flow: column nowrap;
  justify-content: center;*/
}
.landing-page {
  flex: 1 1 auto;
}
.landing-container {
  height: 100%;
}
.App {
  text-align: center;
  height: 100%;
}
.height100 {
  height: 100%;
}
/*#jsoneditor {
  height: 450px !important;
}*/

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ul-how-to {
  list-style-type: square;
}
.ul-how-to > li {
  font-size: 1rem;
}

.div-contact {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
}
.p-contact {
  font-size: 1rem;
}